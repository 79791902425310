import Immutable, { List, Map } from 'immutable';

import { 
    HANDLE_COLUMN_INPUT, 
    CLEAR_FROM_STATE,
    HANDLE_NAME_INPUT,
    SET_ALL_TEMPLATES,
    ADD_TEMPLATE_TO_TABLE,
    SET_TEMPLATE_ID,
    SET_TEMPLATE_STATE,
    OPEN_MODAL,
} from './ACTION_TYPES';

import { 
    CREATE_EXCEPTION,
    MINIMUM_VALUE,
    MAXIMUM_VALUE,
    SEGMENT_BY,
    TEMPLATE_TYPE,
    COLUMN_SORT_ORDER,
    APPLICATION,
    NONE,
    TEMPLATE_NAME,
    DISTANCE,
    NEW_SEGMENTS_WHEN_CLEARED,
    SPLIT_LEFT_RIGHT_SEGMENTS,
    AMOUNT
} from '../configs/KEY_NAMES';


const SELECT_TEMPLATE_TO_SHARE = 'SELECT_TEMPLATE_TO_SHARE';
export const setShareTemplateID = (shareID)=> ({
    type: SELECT_TEMPLATE_TO_SHARE,
    shareID,

});

export const openFormModal = () => ({
    type: OPEN_MODAL
});

export const setTemplateState = (templateState) => ({
    type: SET_TEMPLATE_STATE,
    templateState
});

export const setTemplateID = (templateID, isRetro) => ({
    type: SET_TEMPLATE_ID,
    templateID,
    isRetro,

});

export const setAllTemplates = (allTemplates) => ({
    type: SET_ALL_TEMPLATES,
    allTemplates
});

export const addTemplateToTable = (newTemplate) => ({
    type: ADD_TEMPLATE_TO_TABLE,
    newTemplate
});

export const handleColumnInput  = ( _id, value, defaultValues=null ) => ({
    type: HANDLE_COLUMN_INPUT,
    _id,
    value,
    defaultValues,
});

export const clearFormState = () => ({
    type: CLEAR_FROM_STATE
});

const TOGGLE_RETRO_TEMPLATE = 'TOGGLE_RETRO_TEMPLATE';
export const toggleRetroTemplate = () => ({
    type: TOGGLE_RETRO_TEMPLATE,
});

export const handleTemplateNameInput = (name) => ({
    type: HANDLE_NAME_INPUT,
    name
})

const formatData = (oldState) => {
    let newFormState = Map({});
    const columnsSortOrder = oldState.get('columnsSortOrder', List([]));
    const name = oldState.get('name');
    const templateType = oldState.get(TEMPLATE_TYPE);

    newFormState = newFormState.set('columnsSortOrder', columnsSortOrder);
    newFormState = newFormState.set('name', name);
    newFormState = newFormState.set(TEMPLATE_TYPE, templateType);
    columnsSortOrder.forEach(key => {
        newFormState = newFormState.set(key, true);

        const subKeys = oldState.get(key, Map({})).keySeq();

        subKeys.forEach(subKey => {
            const newKey = `${key}/${subKey}`;
            const value = oldState.getIn([key, subKey]);
            if (subKey ===  MINIMUM_VALUE || subKey === MAXIMUM_VALUE){
                const createExceptionKey = `${key}/${CREATE_EXCEPTION}`;
                if (!newFormState.has(createExceptionKey)){
                    newFormState  = newFormState.set(createExceptionKey, true);
                }
            };
            newFormState = newFormState.set(newKey, value);
        });


    });
    const middleKey = oldState.getIn([SEGMENT_BY, 'value']);
    newFormState = newFormState.set(SEGMENT_BY, middleKey);
    oldState = oldState.deleteIn([SEGMENT_BY, 'value']);

    const segmentBySubKeys = oldState.get(SEGMENT_BY, Map({})).keySeq();

    segmentBySubKeys.forEach(subKey => {
        let newKey = `${SEGMENT_BY}/${middleKey}/${subKey}`;
        if (
            subKey === NEW_SEGMENTS_WHEN_CLEARED 
            || subKey === SPLIT_LEFT_RIGHT_SEGMENTS
        ){
            newKey = `${SEGMENT_BY}/${subKey}`
        }
        if (
            subKey === MINIMUM_VALUE
            || subKey === MAXIMUM_VALUE
        ) {

        };
        const value = oldState.getIn([SEGMENT_BY, subKey]);
        newFormState = newFormState.set(newKey, value);
    });

    return newFormState;
};

let  initalState = Immutable.fromJS({
    // template_type: APPLICATION, // the  template type
    // template_type: 'retroreflectivity', // the  template type
    // segment_by: NONE, // the  template type
    // columnsSortOrder: [], // array of keys to sign;
    // name: '',
    allTemplates: [],
    templateModalOpen: false,
    shareTemplateID: '',
    retroTemplate: false,
});

const templateOptionsInit = Immutable.fromJS({
    template_type: APPLICATION, // the  template type
    segment_by: NONE, // the  template type
    columnsSortOrder: [], // array of keys to sign;
    name: '',
});

initalState = initalState.merge(templateOptionsInit);


const templatesReducer = (state=initalState, action) => {
    switch(action.type){

        case SELECT_TEMPLATE_TO_SHARE:
            const { shareID='' } =action;
            return state.set('shareTemplateID', shareID)

        case SET_TEMPLATE_ID:
            const { templateID, isRetro } = action;
            return state
                .set('templateID', templateID)
                .set('templateModalOpen', true)
                .set('retroTemplate', isRetro);

        case SET_TEMPLATE_STATE:
            const { templateState } = action;
            let newTemplateState = formatData(templateState);
            return state.merge(newTemplateState);


        case OPEN_MODAL:
            return state.set('templateModalOpen', true);

        case TOGGLE_RETRO_TEMPLATE:
            const currentRetroState = state.get('retroTemplate', false);
            let resetState = templateOptionsInit
                .set('retroTemplate', !currentRetroState)
                .set('templateModalOpen', true)
                .set('shareTemplateID', state.get('shareTemplateID', ''))
                .set('allTemplates', state.get('allTemplates', List([])))

            if (!currentRetroState){
                resetState = resetState.set('template_type', 'retro')
            } else {
                resetState = resetState.set('template_type', APPLICATION)
            }
            return resetState;

        case SET_ALL_TEMPLATES:
            const { allTemplates } = action;
            return state.set('allTemplates', allTemplates);

        case HANDLE_NAME_INPUT:
            const {name} = action
            return state.set('name', name)

        case HANDLE_COLUMN_INPUT:

            let {_id, value, defaultValues} = action;

            const idsArray = _id.split('/');
            const mainID = idsArray[0];
            const secondaryID = idsArray[1] || '';

            let columnSortOrder = state.get('columnsSortOrder', List([]));

            // keeping track of the column order
            // does not apply to segment_by,template_type, or template name 
            // only for the first column in the rows whos idsArray length will always be 1
            // average_speed not average_speed/units
            // only used for checkboxes where value is true or false
            if (
                mainID !== SEGMENT_BY 
                && mainID !== TEMPLATE_TYPE 
                && mainID !== TEMPLATE_NAME
                && idsArray.length === 1
            ){
                if (value === false && mainID){
                    const columnIndex = columnSortOrder.indexOf(mainID);
                    if (columnIndex !== -1){
                        columnSortOrder = columnSortOrder.delete(columnIndex);
                    }
                } else {
                    columnSortOrder = columnSortOrder.push(mainID);
                };

            }

            // value here is a number
            if (secondaryID === COLUMN_SORT_ORDER) {
                const columnIndex = columnSortOrder.indexOf(mainID);
                columnSortOrder = columnSortOrder.splice(columnIndex, 1);
                columnSortOrder = columnSortOrder.splice(value-1, 0, mainID);
            };

            // if (_id === `${SEGMENT_BY}/${DISTANCE}/${AMOUNT}`){
            //     value = value === '' ? 0 : value;
            // };

            let updatedState =  state
                .set(_id, value)
                .set('columnsSortOrder', columnSortOrder);

            // when a row is first selected we set the provided default values 
            // We don't want this default values to override values that have
            // already been selected in cases where a row is selected,
            // deslected, then selected again. To prevent that, we merge the
            // current state into the default values, that way if any of them
            // already exist they will overrite the default values.  
            if (defaultValues !== null){
                const _defaultValuesImm = Immutable.fromJS(defaultValues);
                updatedState = Immutable.merge(_defaultValuesImm, updatedState);
            };


            return state.merge(updatedState);

        case CLEAR_FROM_STATE:
            const currentTemplates = state.get('allTemplates', List([]));
            let newState = initalState.set('allTemplates', currentTemplates);
            newState = newState.merge(templateOptionsInit);
            return newState;


        default:
            return state;
    }
};

export default templatesReducer;
