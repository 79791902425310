import { fromJS, List } from 'immutable';


export const reportsActionTypes = {
    // action.bool
    'reports/setLoading': 'reports/setLoading',

    // action.reports
    'reports/setReports': 'reports/setReports',

    // action.templateOptions
    'reports/setTemplateOptions': 'reports/setTemplateOptions',
    
    // action.templateObj
    'reports/updateTemplateOptions': 'reports/updateTemplateOptions',
    // action.id
    'reports/delete': 'reports/delete',
};

const SET_SELECT_ROW_ID = "SET_SELECT_ROW_ID";
const ADD_TEMPLATE_TO_TABLE = "ADD_TEMPLATE_TO_TABLE";

export const addTemplateToTable = (_newTemplate) => ({
    type: ADD_TEMPLATE_TO_TABLE,
    _newTemplate
});
const SET_TEMPLATE_OPTIONS = "SET_TEMPLATE_OPTIONS";
export const setTemplateOptions = (paintTemplates, retroTemplates) => ({
    type: SET_TEMPLATE_OPTIONS,
    paintTemplates,
    retroTemplates,
});

// Action creators

export const setSelectedRowID = id => ({
    type: SET_SELECT_ROW_ID,
    rowID: id
});

const SET_ALL_RETRO_REPORTS = 'SET_ALL_RETRO_REPORTS';
export const setAllRetroReports = retroReports => ({
    type: SET_ALL_RETRO_REPORTS,
    retroReports,
});

const ADD_RETRO_REPORT = 'ADD_RETRO_REPORT';
export const addRetroReport = newRetroReport => ({
    type: ADD_RETRO_REPORT,
    newRetroReport,
});

const DELETE_RETRO_REPORT = 'DELETE_RETRO_REPORT';
export const deleteRetroReport = retroIndex => ({
    type: DELETE_RETRO_REPORT,
    retroIndex,
});





const initialState = fromJS({
    allTemplates: [],
    paintTemplates: [],
    retroTemplates: [],
    loading: true,
    reports: [],
    isRetroLoading: true,
    retroReports: [],
    selectedRowID: "grandTotal",
    loadingTemplates: true,

});

const reportsReducer = (state=initialState, action) => {
    switch(action.type){

        case ADD_TEMPLATE_TO_TABLE:
            const {_newTemplate} = action;
            const _templateID = _newTemplate.get('id', null);
            let updatedAllTemplateData;

            const allTemplateData = state.get('allTemplates', List([]));

            const templateIndex = allTemplateData.findIndex((_template, index) => {
                const _id = _template.get('id', null);
                return _id === _templateID;
            });


            if (templateIndex !== -1) {
                updatedAllTemplateData = allTemplateData.set(templateIndex, _newTemplate);
            } else {
                updatedAllTemplateData = allTemplateData.unshift(_newTemplate);
            }

            return state.set('allTemplates', updatedAllTemplateData);
        case reportsActionTypes['reports/setLoading']:
            const _bool = action.bool;

            return state.set('loading', _bool);

        case ADD_RETRO_REPORT:
            const { newRetroReport } = action;
            return state.get('retroReports', List([])).unshift(newRetroReport);

        case SET_ALL_RETRO_REPORTS:
            const {retroReports} = action;
            return state
                .set('retroReports', retroReports.get('reports', List([])))
                .set('isRetroLoading', false);

        case reportsActionTypes['reports/setReports']:
            // Shape the api reports response into the shape we want for the
            // table
            let _reports = action.reports || List([]); 

            _reports = _reports.map(reportObj => {
                const equipment = reportObj.get("equipment", "None");
                let equipmentListString = ""; 

                equipment.forEach((equip, index) => {                    
                    if (equipment.size > 1 && index > 0) {
                        equipmentListString += `, ${equip}`;
                    } else {
                        equipmentListString += equip;
                    }
                });

                reportObj = reportObj.set('equipment', equipmentListString);
                return reportObj;
            });


            let _reportsState = state.set('reports', _reports);
            _reportsState = _reportsState.set('loading', false);

            return state.merge(_reportsState);

        case SET_TEMPLATE_OPTIONS:

            const {paintTemplates, retroTemplates} = action;

            let allTemplates = paintTemplates.concat(retroTemplates);
            allTemplates = allTemplates.sort((a, b) => {
                const aDate = Date.parse(a.get('last_updated_date'));
                const bDate = Date.parse(b.get('last_updated_date'));
                const sortNumber = aDate === bDate ? 0 : aDate < bDate ? 1 : -1;
                return sortNumber;
            })
            const _templateOptionsState = state
                .set('allTemplates', allTemplates)
                .set('paintTemplates', paintTemplates)
                .set('retroTemplates', retroTemplates)
                .set('loadingTemplates', false);

            return state.merge(_templateOptionsState);

        case reportsActionTypes['reports/updateTemplateOptions']:
            const _templateObj = action.templateObj;
            let _newTemplates = state.get('templateOptions', List([]));
            // index value to delete or -1 for new template
            const newTemplate = _newTemplates.indexOf(_templateObj);

            if (newTemplate) _newTemplates = _newTemplates = _newTemplates.push(_templateObj);
            // in this case newTemplate is actually the old tempalte index
            else _newTemplates = _newTemplates = _newTemplates.delete(newTemplate);

            return state
                .set('templateOptions', _newTemplates);

        case DELETE_RETRO_REPORT:

            const retroIndex = action.retroIndex;
            return state.deleteIn(['retroReports', retroIndex]);

        case reportsActionTypes['reports/delete']:

            const reportIndex = action.reportIndex;
            return state.deleteIn(['reports', reportIndex]);

        case SET_SELECT_ROW_ID:
            const tableRowID = action.rowID;
            return state.set("selectedRowID", tableRowID);

        default:
            return state;
    };
};


export default reportsReducer;
