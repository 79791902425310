import Immutable from 'immutable';
import { combineReducers } from "redux";
import truck from "./truck";
// import hardware from "./hardware";
// import carriage from "./carriage";

export default combineReducers({
    truck,
    // hardware,
    // carriage
});
